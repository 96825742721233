@import url(https://fonts.googleapis.com/css2?family=Pacifico&family=Bebas+Neue&family=Oswald&family=Yanone+Kaffeesatz&family=Odibee+Sans&family=Fjalla+One&family=Squada+One&family=Teko&family=Anton&family=Bungee+Inline&Pacifico&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  outline: none !important;
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Teko";
}

/* grid */
.grid {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  width: 100%;
}

.grid li {
  position: relative;
  float: left;
  font-size: 8rem;
  line-height: 1.5;
  max-height: 290px;
  text-align: center;
  margin-right: 2rem;
  display: block;
}

/* Common styles for the letters */
.grid li span {
  display: inline-block;
  font-weight: 900;
  line-height: 1;
  position: relative;
  color: hsla(0, 0%, 0%, 0.6);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 550px;
  -ms-perspective: 550px;
  perspective: 550px;
  z-index: 1;
}

.grid li span:before,
.grid li span:after {
  position: absolute;
  content: attr(data-letter);
  line-height: inherit;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  transition: all 0.3s;
}

.grid li span:before {
  text-shadow: none;
  color: hsla(0, 0%, 0%, 0.12);
}

/* Colors */

.ot-letter-left {
  background: transparent;
}
.ot-letter-left span {
  text-shadow: 1px 4px 6px transparent, 0 0 0 hsla(0, 0%, 0%, 0.3),
    1px 4px 6px transparent;
}
.ot-letter-left span:after {
  color: transparent;
}
.ot-letter-left:hover span:after {
  color: rgba(255, 255, 255, 0.1);
}

.ot-letter-right {
  background: transparent;
}
.ot-letter-right span {
  text-shadow: 1px 4px 6px transparent, 0 0 0 hsla(0, 0%, 0%, 0.3),
    1px 4px 6px transparent;
}
.ot-letter-right span:after {
  color: transparent;
}
.ot-letter-right:hover span:after {
  color: rgba(255, 255, 255, 0.1);
}

.ot-letter-top {
  background: transparent;
}
.ot-letter-top span {
  text-shadow: 1px 4px 6px transparent, 0 0 0 hsla(0, 0%, 0%, 0.3),
    1px 4px 6px transparent;
}
.ot-letter-top span:after {
  color: transparent;
}
.ot-letter-top:hover span:after {
  color: rgba(255, 255, 255, 0.1);
}

.ot-letter-bottom {
  background: transparent;
}
.ot-letter-bottom span {
  text-shadow: 1px 4px 6px transparent, 0 0 0 hsla(0, 0%, 0%, 0.3),
    1px 4px 6px transparent;
}
.ot-letter-bottom span:after {
  color: transparent;
}
.ot-letter-bottom:hover span:after {
  color: rgba(255, 255, 255, 0.1);
}

/* Left */
.ot-letter-left span:before,
.ot-letter-left span:after {
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

.ot-letter-left span:before {
  -webkit-transform: scale(1.08, 1) skew(0deg, 1deg);
  transform: scale(1.08, 1) skew(0deg, 1deg);
}

.ot-letter-left span:after {
  text-shadow: -1px 0px 0px hsla(360, 100%, 100%, 0.1),
    3px 0px 1px hsla(0, 0%, 0%, 0.4);
  -webkit-transform: rotateY(-15deg);
  transform: rotateY(-15deg);
}

.ot-letter-left:hover span:before {
  -webkit-transform: scale(0.85, 1) skew(0deg, 20deg);
  transform: scale(0.85, 1) skew(0deg, 20deg);
}

.ot-letter-left:hover span:after {
  -webkit-transform: rotateY(-40deg);
  transform: rotateY(-40deg);
}

/* Right */
.ot-letter-right span:before,
.ot-letter-right span:after {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.ot-letter-right span:before {
  -webkit-transform: scale(0.85, 1) skew(0deg, 1deg);
  transform: scale(0.85, 1) skew(0deg, 1deg);
}

.ot-letter-right span:after {
  text-shadow: 1px 0px 0px hsla(360, 100%, 100%, 0.1),
    -3px 0px 1px hsla(0, 0%, 0%, 0.4);
  -webkit-transform: rotateY(15deg);
  transform: rotateY(15deg);
}

.ot-letter-right:hover span:before {
  -webkit-transform: scale(0.85, 1) skew(0deg, -20deg);
  transform: scale(0.85, 1) skew(0deg, -20deg);
}

.ot-letter-right:hover span:after {
  -webkit-transform: rotateY(40deg);
  transform: rotateY(40deg);
}

/* Up */
.ot-letter-top span:before,
.ot-letter-top span:after {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.ot-letter-top span:before {
  -webkit-transform: scale(1, 0.95) skew(-4deg, 0deg);
  transform: scale(1, 0.95) skew(-4deg, 0deg);
}

.ot-letter-top span:after {
  text-shadow: 0px 1px 0px hsla(360, 100%, 100%, 0.1),
    0px -3px 1px hsla(0, 0%, 0%, 0.4);
  -webkit-transform: rotateX(-15deg);
  transform: rotateX(-15deg);
}

.ot-letter-top:hover span:before {
  -webkit-transform: translateY(-0.05em) scale(1, 0.55) skew(-10deg, 0deg);
  transform: translateY(-0.05em) scale(1, 0.55) skew(-10deg, 0deg);
}

.ot-letter-top:hover span:after {
  -webkit-transform: translateY(-0.035em) rotateX(-40deg);
  transform: translateY(-0.035em) rotateX(-40deg);
}

/* Down */
.ot-letter-bottom span:before,
.ot-letter-bottom span:after {
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
}

.ot-letter-bottom span:before {
  -webkit-transform: scale(1, 1.05) skew(4deg, 0deg);
  transform: scale(1, 1.05) skew(4deg, 0deg);
}

.ot-letter-bottom span:after {
  text-shadow: 0px -1px 0px hsla(360, 100%, 100%, 0.1),
    0px 3px 1px hsla(0, 0%, 0%, 0.4);
  -webkit-transform: rotateX(15deg);
  transform: rotateX(15deg);
}

.ot-letter-bottom:hover span:before {
  -webkit-transform: translateY(-0.035em) scale(1, 1.2) skew(10deg, 0deg);
  transform: translateY(-0.035em) scale(1, 1.2) skew(10deg, 0deg);
}

.ot-letter-bottom:hover span:after {
  -webkit-transform: translateY(0.045em) rotateX(40deg);
  transform: translateY(0.045em) rotateX(40deg);
}

.circleContainer {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 100px;
  z-index: 10000;

  transition: all 0.3s ease-in-out 0s;

  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
  -webkit-animation: fade 5s infinite;
          animation: fade 5s infinite;
}

@media (min-width: 768px) {
  .circleContainer {
    left: 75px;
  }
}

.circle {
  width: 40px;
  height: 40px;
  font-size: 0.6rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  font-weight: 700;
  color: #313133;
  background: #ffffff;
  text-align: center;
  padding-top: 14px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  box-shadow: 0px 0px 10px 10px rgba(255, 255, 255, 0.5);
  margin: auto;
}

.circleDot {
  margin: auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: transparent;
  border: #ffffff solid 1px;
  box-shadow: 0px 0px 2px 1px rgba(255, 255, 255, 0.2);
}

.circleBar {
  margin: auto;
  width: 1px;
  height: 30px;
  background-color: #ffffff;
  box-shadow: 0px 0px 2px 1px rgba(255, 255, 255, 0.2);
}

.circle::before {
  content: "";
  border-radius: 500px;
  width: 70px;
  height: 70px;
  border: 6px solid #ffffff;
  box-shadow: 0 0 60px 60px rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 75%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
}

.circle:hover,
.circle:focus {
  opacity: 1;
}

.circle:hover::before,
.circle:focus::before {
  opacity: 1;
}

.circle::after {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #ffffff;
  position: absolute;
  z-index: -1;
  top: 75%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-animation: ring 2.5s infinite;
          animation: ring 2.5s infinite;
}

.circleContainer:hover,
.circleContainer:hover::after,
.circleContainer:focus::after {
  -webkit-animation: none;
          animation: none;
  opacity: 0.7;
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.7;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.7;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 200px;
    height: 200px;
    opacity: 0;
  }
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 200px;
    height: 200px;
    opacity: 0;
  }
}

.glass {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.swipe-me {
  width: 90px;
  height: 1px;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  text-align: right;
  opacity: 0;
}

.swipe-me.active{
	opacity: 1;
}
.swipe-me b {
  width: 100px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  -webkit-transform-origin: left;
          transform-origin: left;
  float: right;
}
.swipe-me span {
  display: block;
  position: relative;
}

.swipe-me span::before {
  -webkit-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
  animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
}

.swipe-me span::before {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 90px;
  height: 1px;
  background: #fff;
  content: "";
}

@-webkit-keyframes elasticus {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
  }
  50% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
  }
}
@keyframes elasticus {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
  }
  50% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
  }
}

.slider>.slide{
	opacity: 0;
}
.slide.selected {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in {
	0% {
	  opacity: 0;
	}
	100% {
	  opacity: 1;
	}
  }
  @keyframes fade-in {
	0% {
	  opacity: 0;
	}
	100% {
	  opacity: 1;
	}
  }

  @media (max-width: 768px) {
    .projects .carousel .control-dots .dot
    {
      margin: 0px 3px 0px 3px;
    }
    }  


